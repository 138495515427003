<template>
  <div>
    <div class="w-full" v-if="type !== 'color'">
      <label v-if="label" class="block text-xs font-semibold text-typography-primary font-geist py-[6px]">
        {{ label }}
        <span v-if="required" class="text-red-600">*</span>
      </label>
      <div class="rounded-md flex space-x-4" :class="[inputClasses]">
        <div v-if="iconLeft" class="pointer-events-none inset-y-0 flex-none flex items-center">
          <div v-if="typeof iconLeft === 'string'" :class="iconClasses.text">
            {{ iconLeft }}
          </div>
          <component v-else :is="iconLeft" :class="iconClasses.icon" />
        </div>
        <div class="flex-1">
          <input
            v-if="type !== 'color'"
            :type="inputType"
            v-bind="$attrs"
            step="any"
            class="block w-full outline-0 bg-transparent pulse-input"
            :value="modelValue"
            v-hubble="'input'"
            @input="({ target }: Event) => emit('update:modelValue', (target as HTMLInputElement).value)"
            ref="input"
          />
        </div>
        <div class="flex items-center" v-if="type === 'password'">
          <button
            v-tooltip="tooltip"
            @click.stop.prevent="showPassword = !showPassword"
            type="button"
            class="text-neutral-400"
          >
            <LineEyeSlash v-if="showPassword" class="fill-path-current h-4 w-4" />
            <LineEye v-else class="fill-path-current h-4 w-4" />
          </button>
        </div>
        <div class="flex-none flex items-center" v-if="$slots.suffix || iconRight">
          <slot name="suffix">
            <div v-if="iconRight" class="pointer-events-none inset-y-0 flex-none flex items-center">
              <div v-if="typeof iconRight === 'string'" :class="iconClasses.text">
                {{ iconRight }}
              </div>
              <component v-else :is="iconRight" :class="iconClasses.icon" />
            </div>
          </slot>
        </div>
      </div>
    </div>
    <div v-else>
      <ColorInput
        :label="label"
        :model-value="modelValue"
        @input="({ target }: Event) => emit('update:modelValue', (target as HTMLInputElement).value)"
      />
    </div>
    <span class="text-typography-disabled text-xs">{{ hint }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import * as icons from '../icons';
import ColorInput from './ColorInput.vue';
import { LineEyeSlash, LineEye } from '../icons';

const showPassword = ref(false);
const tooltip = computed(() => (showPassword.value ? 'Hide password' : 'Show password'));

const IconMap: Record<string, any> = {
  ...icons,
};

const input = ref<HTMLElement | null>(null);

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  error: {
    type: Boolean,
    default: false,
  },
  iconLeft: {
    type: [String, Function],
  },
  iconRight: {
    type: [String, Function],
  },
  label: {
    type: String,
    default: '',
  },
  variant: {
    type: String,
    validator: (variant: string) =>
      ['text', 'password', 'date', 'email', 'url', 'currency', 'phone', 'number', 'percent'].includes(variant),
    default: 'text',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  hint: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
});

const inputType = computed(() => {
  if (isCurrencyOrPercent) {
    return 'number';
  }

  if (props.type === 'password') {
    return showPassword.value ? 'text' : 'password';
  }

  return props.type ?? props.variant;
});

const emit = defineEmits(['update:modelValue']);

const isCurrencyOrPercent = props.variant === 'currency' || props.variant === 'percent' ? true : false;

const inputClasses = computed(() => {
  return {
    'h-[44px] ring-1 ring-inset !py-3 rounded-md focus-within:!ring-primary-700 focus-within:!ring-2 focus-within:!outline-none text-md font-normal text-typography-primary bg-neutral-white placeholder:text-typography-disabled !px-4':
      true,
    'disabled:!bg-neutral-200 disabled:!text-typography-disabled disabled:!cursor-not-allowed text-sm': true,
    'ring-2 ring-danger-700': props.error,
    'ring-neutral-300': !props.error,
  };
});

const iconClasses = computed(() => ({
  icon: 'h-[14px] w-[14px] text-typography-secondary',
  text: 'text-typography-secondary',
}));

defineExpose({
  input,
});
</script>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}
input.pulse-input:focus-visible {
  outline: none;
}
input[data-autocompleted] {
  background-color: transparent !important;
}
</style>
